import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'about Us',
    component: () => import('@/pages/AboutUs/index.vue'),
    meta: {
      title: 'title'
    }
  },
  {
    path: '/news',
    name: 'news',
    component: () => import('@/pages/News/index.vue')
  },
  {
    path: '/business&brand',
    name: 'business & brand',
    component: () => import('@/pages/Business&Brand/index.vue')
  },
  {
    path: '/contact_us',
    name: 'contact Us',
    component: () => import('@/pages/ContactUs/index.vue')
  },
  {
    path: '/products',
    name: 'products',
    component: () => import('@/pages/Products/index.vue')
  },
]

const productsChildRoutes: Array<RouteRecordRaw> = [
  {
    path: '/products/H2',
    name: 'products/H2',
    component: () => import('@/pages/Products/productions/H2.vue')
  },
  {
    path: '/products/H3',
    name: 'products/H3',
    component: () => import('@/pages/Products/productions/H3.vue')
  },
  {
    path: '/products/510i',
    name: 'products/510i',
    component: () => import('@/pages/Products/productions/510i.vue')
  },
  {
    path: '/products/CD1',
    name: 'products/CD1',
    component: () => import('@/pages/Products/productions/CD1.vue')
  },
  {
    path: '/products/D10',
    name: 'products/D10',
    component: () => import('@/pages/Products/productions/D10.vue')
  },
  {
    path: '/products/D13',
    name: 'products/D13',
    component: () => import('@/pages/Products/productions/D13.vue')
  },
  {
    path: '/products/D14',
    name: 'products/D14',
    component: () => import('@/pages/Products/productions/D14.vue')
  },
  {
    path: '/products/D15',
    name: 'products/D15',
    component: () => import('@/pages/Products/productions/D15.vue')
  },
  {
    path: '/products/D16',
    name: 'products/D16',
    component: () => import('@/pages/Products/productions/D16.vue')
  },
  {
    path: '/products/E20',
    name: 'products/E20',
    component: () => import('@/pages/Products/productions/E20.vue')
  },
  {
    path: '/products/E21',
    name: 'products/E21',
    component: () => import('@/pages/Products/productions/E21.vue')
  },
  {
    path: '/products/D21',
    name: 'products/D21',
    component: () => import('@/pages/Products/productions/D21.vue')
  },
  {
    path: '/products/D16mini',
    name: 'products/D16mini',
    component: () => import('@/pages/Products/productions/D16mini.vue')
  },
  {
    path: '/products/G1',
    name: 'products/G1',
    component: () => import('@/pages/Products/productions/G1.vue')
  },
  {
    path: '/products/M3',
    name: 'products/M3',
    component: () => import('@/pages/Products/productions/M3.vue')
  },
  {
    path: '/products/D20',
    name: 'products/D20',
    component: () => import('@/pages/Products/productions/D20.vue')
  },
  {
    path: '/products/C3',
    name: 'products/C3',
    component: () => import('@/pages/Products/productions/C3.vue')
  },
  {
    path: '/products/C8',
    name: 'products/C8',
    component: () => import('@/pages/Products/productions/C8.vue')
  },
  {
    path: '/products/D25',
    name: 'products/D25',
    component: () => import('@/pages/Products/productions/D25.vue')
  },
  {
    path: '/products/D26',
    name: 'products/D26',
    component: () => import('@/pages/Products/productions/D26.vue')
  },

]


routes.push(...productsChildRoutes)


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

export default router
